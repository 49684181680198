
// Fonts
// @import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Material Design Lite
// @import "node_modules/material-design-lite/material";
@import "node_modules/material-design-lite/src/material-design-lite";


//GENERAL
.mdl-navigation__link {
    text-transform: uppercase;
}
.social-btn__twitter {
  background-image: url('https://www.gstatic.com/images/icons/material/system/2x/post_twitter_white_24dp.png');
}
.social-btn__facebook {
  background-image: url('https://www.gstatic.com/images/icons/material/system/2x/post_facebook_white_24dp.png');
}
.paginator {
    text-align: center;
}
.searchdiv {
    background: #ECEFF1;
    margin-top: 1em;
    border: 2px solid #90A4AE;
    border-radius: 0.2em;
}
.searchdiv .mdl-textfield__input {
    border: 1px solid #D32F2F;
    padding: 3px;
    line-height: 2;
}
.searchdiv .mdl-textfield__label {
    padding: 3px;
    line-height: 2;
}
.searchdiv .mdl-button {
    margin-left: 1em;
}
.searchtitle {
    text-align: center;
    color: #E53935;
    font-weight: normal;
    font-size: 1.5em;
}
@media (max-width: 1024px) {
    .mdl-layout-title img {
        height: 49px;
        width: 82px;
    }
}
@media (min-width: 1025px) {

}
.search-section-title {
    color: unquote("rgb(#{$palette-red-800})");
}
//HOME
.home-section-title {
    color: unquote("rgb(#{$palette-pink-900})");
}
.random-link {
    text-decoration: none;
}
.random-title {
    color: unquote("rgb(#{$palette-teal-600})");
    font-size:2em;
    line-height: 1.15;
}
.random-image {
    width:100%;
}

//VIDEOS
.video-container {
    margin: 0 auto;
    padding-top: 2em;
    text-align: center;
}
.fb-video-title {
    font-size: 2em;
    line-height: 1;
    color: unquote("rgb(#{$palette-teal-700})");
    text-align:left;
}

//RECIPE
.recipe-title {
    color: unquote("rgb(#{$palette-pink-900})");
}
.recipe-image {
    width: 100%;
}
.recipe-description {
    font-size:1.25em;
    line-height: 1.5;
}
.yield {
    color: unquote("rgb(#{$palette-pink-900})");
}

.ingredients-title {
    color: unquote("rgb(#{$palette-teal-700})");
}

.instructions-title {
    color: unquote("rgb(#{$palette-teal-700})");
}


@media print {
    * {
        margin: 0;
        padding: 0;
        border: 0;
        color: #000;
        line-height: 1;
        display: inline-block;
        position: relative;
    }
    .adsbygoogle, .mdl-layout-spacer, .mdl-layout__drawer-button, .mdl-navigation, .recipe-action, .recipe-image, script, a, head, link {
        display: none !important;
    }
}
