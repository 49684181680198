@import "node_modules/material-design-lite/src/color-definitions";
@import "node_modules/material-design-lite/src/functions";

$color-primary: $palette-red-800;
$color-primary-dark: $palette-red-900;
$color-accent: $palette-light-blue-A200;

$layout-header-mobile-row-height: 56px;
$layout-header-desktop-row-height: 82px;

/* LIST */

$list-border: 8px;
$list-min-height: 40px;
$list-min-padding: 10px;
$list-bottom-padding: 20px;
$list-avatar-text-left-distance: 54px;
$list-icon-text-left-distance: 54px;

$list-avatar-size: 40px;
$list-icon-size: 24px;

$list-two-line-height: 72px;
$list-three-line-height: 88px;

$list-main-text-text-color: unquote("rgba(#{$color-black}, 0.87)");
$list-supporting-text-text-color: unquote("rgba(#{$color-black}, 0.54)");
$list-icon-color: unquote("rgb(#{$palette-teal-600})");
$list-avatar-color: white;
